import './scripts/publicPath'
import 'console-polyfill'
import 'normalize.css/normalize.css'
import './main.scss'
import $ from 'jquery'
import 'swiper/swiper-bundle.min.css'
import '@fancyapps/fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'

import installCE from 'document-register-element/pony'

// window.jQuery = $
window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.preloadAfterLoad = true

installCE(window, {
  type: 'force',
  noBuiltIn: true
})

// A $( document ).ready() block.
$(document).ready(function () {
  console.log('ready!')
  $('a[href*=\\#]').on('click', function (event) {
    // event.preventDefault();
    $('html,body').animate({ scrollTop: $(this.hash).offset().top - 120 }, 500)
  })
})

// Smooth scrolling with links
// Smooth scrolling when the document is loaded and ready
$(document).ready(function () {
  if (window.location.hash) {
    $('html,body').not('.archive').animate({
      scrollTop: $(window.location.hash).offset().top - 120
    }, 500);
  }
})

function setCookie (cName, value, exdays) {
  var exdate = new Date()
  exdate.setDate(exdate.getDate() + exdays)
  var cValue =
    escape(value) + (exdays == null ? '' : '; expires=' + exdate.toUTCString())
  document.cookie = cName + '=' + cValue
}

function getCookie (name) {
  var re = new RegExp(name + '=([^;]+)')
  var value = re.exec(document.cookie)
  return (value != null) ? unescape(value[1]) : null
}

function loadScript (url) {
  const element = document.createElement('script')
  element.src = url
  document.body.appendChild(element)
}

function loadScripts () {
  if (window.componentsUrl) {
    loadScript(window.componentsUrl)
    loadScript(window.lazySizesPath)
  }
}

function isMobile () {
  return window.matchMedia('(max-width: 700px)').matches
}

function userVisited () {
  const visited = getCookie('fvvisited')
  return visited === 'yes'
}

window.addEventListener('load', () => {
  if (isMobile()) {
    checkSession()
    setTimeout(() => {
      if (!userVisited()) {
        loadScripts()
        setCookie('fvvisited', 'yes', 365)
      }
    }, 3000)
  } else {
    loadScripts()
  }
})

// Check session for our cookie
function checkSession () {
  if (userVisited()) {
    loadScripts()
  }
}

// Load scripts and set cookie on interaction
function loadScriptsOnInteraction () {
  if (!userVisited() && isMobile()) {
    loadScripts()
    setCookie('fvvisited', 'yes', 365)
  }
}

$(document).one(
  'mousedown mousemove keydown touchstart resize scroll',
  loadScriptsOnInteraction
)
